<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <v-card>
      <v-card-title>
        <h3>{{ price.selling_price | money }} / {{ price.name.en }}</h3>
      </v-card-title>
      <v-card-text>
        <p>Above is Base Selling Price which applies to all channels.</p>
        <p>Below is channel's Special Selling Price, overrides base selling price.</p>
        <div
          v-for="channel in matchChannels"
          :key="channel.id"
        >
          <v-divider class="mt-5 pt-2 pb-2" />
          <p class="font-weight-bold">
            {{ channel.code }}
          </p>
          <channel-price-form
            :resource="findChannelPrice(channel)"
            :loading="loading"
            :errors="errors"
            :cols="12"
            :max-price="price.selling_price"
            @submit="form => handleSubmit(form, channel)"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { extractErrors, notifySuccess } from '@/assets/js/utils'
import channelStore from '@/modules/channel/store'
import { storeChannelPrice } from '@api/product'
import { money } from '@core/utils/filter'
import { mdiClose } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import ChannelPriceForm from './ChannelPriceForm.vue'

export default {
  filters: { money },
  components: { ChannelPriceForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    sku: {
      type: Object,
      required: true,
    },
    price: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])
    const matchChannels = computed(() =>
      channelStore.state.channels.filter(ch => (channelStore.state.channel ? channelStore.state.channel.id === ch.id : true)),
    )

    const handleSubmit = async (form, channel) => {
      loading.value = true
      const data = { ...form }
      data.price_id = props.price.id
      data.channel_id = channel.id

      const request = storeChannelPrice(data)
      await request
        .then(() => {
          emit('changed')
          if (matchChannels.value.length === 1) {
            emit('aside', false)
          }
          notifySuccess({ content: "Successfully Set channel's unique price" })
        })
        .catch(err => {
          errors.value = extractErrors(err)
        })
      loading.value = false
    }

    const findChannelPrice = channel => props.price.channel_prices.find(cp => cp.channel_id === channel.id)

    return {
      handleSubmit,
      loading,
      errors,

      matchChannels,
      findChannelPrice,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
