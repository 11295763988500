import { destroySlot, fetchSkuSlots, updateSlot } from '@/api/product'
import { notifyErrors } from '@/assets/js/utils'
import { ref } from '@vue/composition-api'

export default function useSlotForm() {
  const loading = ref(false)
  const selectedRows = ref([]) // of a sku
  const slots = ref([]) // of a sku

  const loadSkuSlots = (sku, queryParams) =>
    fetchSkuSlots({ sku_id: sku.id, ...queryParams })
      .then(res => {
        const { records, pagination } = res.data.data
        slots.value = records

        // remove loading state

        return res
      })
      .catch(error => {
        console.log(error)

        return Promise.reject(error)
      })
      .finally(() => {
        loading.value = false
      })

  /**
   * With Tangible, we store slot group first,
   * then store slot inside the group.
   * group name is irrelevant.
   */

  const deleteSlot = slotId => {
    loading.value = true

    return destroySlot(slotId)
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateAllSlots = async updates => {
    loading.value = true
    for (const slot of selectedRows.value) {
      const data = { ...updates }
      await updateSlot(slot.id, data).catch(notifyErrors)
    }
    loading.value = false
    selectedRows.value = []
  }

  const deleteAllSlots = async () => {
    loading.value = true
    for (const slot of selectedRows.value) {
      await destroySlot(slot.id).catch(err => {
        notifyErrors(err)
      })
    }
    loading.value = false
    selectedRows.value = []
  }

  return {
    loading,
    slots,
    selectedRows,

    loadSkuSlots,
    deleteSlot,

    updateAllSlots,
    deleteAllSlots,
  }
}
