<template>
  <div>
    <div>
      <slot-aside
        v-if="aside === 'slot'"
        :active="true"
        :sku="sku"
        :resource="slot"
        @changed="slot => { closeAside(false); refresh() }"
        @aside="bol => { closeAside(bol); !bol && refresh() }"
      />
    </div>
    <v-row>
      <v-col :cols="4">
        <v-date-picker
          v-model="dates"
          color="primary"
          range
          :events="functionEvents"
        ></v-date-picker>
      </v-col>
      <v-col :cols="8">
        <v-data-table
          v-model="selectedRows"
          :headers="tableColumns"
          :items="matchSlots"
          :options.sync="options"
          :server-items-length="matchSlots.length"
          :loading="loading"
          :height="400"
          :items-per-page="999"
          show-select
          hide-default-footer
          fixed-header
        >
          <!-- Stock -->
          <template #[`item.stock`]="{item}">
            {{ item.stock }}
          </template>

          <!-- Price -->
          <template #[`item.price`]="{item}">
            {{ priceInfo(item) }} (~{{ item.reference_price | money }})
          </template>

          <!-- From To -->
          <template #[`item.started_at`]="{item}">
            {{ item.started_at | dateSimple }} - {{ item.ended_at | dateSimple }}
          </template>

          <!-- Active -->
          <template #[`item.active`]="{item}">
            {{ item.active ? 'Active' : 'Inactive' }}
          </template>

          <!-- actions -->
          <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setSlot(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                    <span>Update</span>
                  </v-list-item-title>
                </v-list-item>

              <!-- <v-list-item
                link
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete (TBC)</span>
                </v-list-item-title>
              </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div>
          <template
            v-if="selectedRows.length"
          >
            <p class="mr-2">
              Bulk Actions to {{ selectedRows.length }} items:
            </p>
            <v-btn
              color="success"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="updateAllSlots({ active: 1 }).then(() => $emit('updated'))"
            >
              <span>Activate</span>
            </v-btn>
            <v-btn
              color="warning"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="updateAllSlots({ active: 0 }).then(() => $emit('updated'))"
            >
              <span>Deactive</span>
            </v-btn>
            <v-btn
              color="error"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="deleteAllSlots({ active: 0 }).then(() => $emit('updated'))"
            >
              <span>Delete</span>
            </v-btn>
          </template>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { withOptions } from '@/assets/js/utils'
import { t } from '@/plugins/i18n'
import { dateSimple, money } from '@core/utils/filter'
import { mdiDeleteOutline, mdiDotsVertical, mdiPencil } from '@mdi/js'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import SlotAside from './SlotAside.vue'
import useSlot from './useSlot'

export default {
  components: { SlotAside },
  filters: { dateSimple, money },
  props: {
    sku: {
      type: Object,
      required: true,
    },
    priceGroups: {
      type: Array,
      default() {
        return []
      },
    },
    rule: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props) {
    const dates = ref([moment().format('YYYY-MM-DD'), moment().add(1, 'week').format('YYYY-MM-DD')])

    const { loadSkuSlots, slots, updateAllSlots, deleteAllSlots, selectedRows, loading } = useSlot()

    const orderedDates = computed(() => (moment(dates.value[1]).isSameOrAfter(moment(dates.value[0])) ? dates.value : dates.value.reverse()))

    const options = ref({
      sortBy: ['started_from'],
      sortDesc: [false],
    })

    const refresh = () =>
      loadSkuSlots(
        props.sku,
        withOptions(options.value, {
          started_at_from: moment(orderedDates.value[0]).startOf('month').format(),
          end_at_to: moment(orderedDates.value[1]).endOf('month').format(),
        }),
      )

    onMounted(() => refresh())

    watch([dates, options], refresh)

    const matchSlots = computed(() =>
      slots.value.filter(slot =>
        moment(slot.started_at).isBetween(moment(orderedDates.value[0]), moment(orderedDates.value[1] || orderedDates.value[0]), 'days', '[]'),
      ),
    )

    const functionEvents = d => (slots.value.find(s => moment(s.started_at).format('YYYY-MM-DD') === moment(d).format('YYYY-MM-DD')) ? ['#000'] : [])

    const tableColumns = [
      { text: 'FROM-TO', value: 'started_at' },
      { text: 'STOCK', value: 'stock' },
      { text: 'PRICE', value: 'price', sortable: false },
      { text: 'ACTIVE', value: 'active' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const slot = ref(null)
    const aside = ref('') // slot

    const setSlot = s => {
      slot.value = s
      aside.value = 'slot'
    }

    const closeAside = bol => {
      if (!bol) {
        slot.value = null
        aside.value = null
      }
    }

    const priceInfo = s => {
      const group = props.priceGroups.find(g => s.price_group_id === g.id)

      return group ? t(group.name) : 'n/a'
    }

    return {
      dates,
      matchSlots,

      functionEvents,

      tableColumns,
      selectedRows,
      loading,
      options,

      slot,
      setSlot,
      aside,
      closeAside,
      priceInfo,
      refresh,

      updateAllSlots,
      deleteAllSlots,

      // icons
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
