<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <translatable-input
              v-model="form.name"
              label="Price Group Name"
              placeholder="Holiday Pricing"
              class="mt-5"
            />

            <div class="d-flex align-center">
            </div>

            <v-divider class="mb-5" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { translatable } from '@/assets/js/utils'
import { TranslatableInput, TranslationLocales } from '@/components'
import { between } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      name: translatable(props.resource, 'name'),
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const costTypeOptions = [
      { title: 'absolute', value: 'absolute' },
      { title: 'percentage', value: 'percentage' },
    ]

    return {
      form,
      formElem,
      validate,
      between,
      costTypeOptions,
    }
  },
}
</script>
