<template>
  <div>
    <v-card class="mt-5">
      <v-card-text>
        <h2 class="text-xl font-weight-semibold mb-2">
          Product Relations
        </h2>
        <div class="mb-4">
          <sync-category-form
            v-if="product"
            :selected-ids="product.categories.map(c => c.id)"
            :sync-base-path="`/merchant/products/${product.id}/categories/sync`"
            :sync-able="syncAble"
          />
          <sync-tag-form
            v-if="product"
            :selected-ids="product.tags.map(c => c.id)"
            :sync-base-path="`/merchant/products/${product.id}/tags/sync`"
            :sync-able="syncAble"
          />
          <sync-location-form
            v-if="product && product.nature !== 'service'"
            :selected-ids="product.locations.map(l => l.id)"
            :sync-base-path="`/merchant/products/${product.id}/locations/sync`"
            :sync-able="syncAble"
          />
          <!--
          <h4 class="mt-5 mb-2">
            Available Channels:
          </h4>
          <p v-if="product.status !== 'published'">
            Publish the product before you can attach to new Channel.
          </p>
          <template v-else>
            <sync-channel-form
              :cols="12"
              :selected-ids="product.channels.map(c => c.id)"
              :sync-base-path="`/merchant/products/${product.id}/channels/sync`"
              :sync-able="syncAble && product.status === 'published'"
            >
            </sync-channel-form>
          </template>

          <h4 class="mt-5 mb-2">
            Channel destinated Notes:
          </h4>
          <p>
            Add special note to the product, so the channel knows to handle the product differently. <br>
            Only channel will be able to see his own notes.
          </p>

          <sync-channel-note-form
            :cols="12"
            :selected-ids="product.channel_notes.map(c => c.id)"
            :sync-base-path="`/merchant/products/${product.id}/channel-notes/sync`"
            :sync-able="syncAble"
          >
          </sync-channel-note-form> -->
        </div>

        <!-- <div class="d-flex justify-center mt-5">
          <v-btn
            color="primary"
            @click="toggleSync"
          >
            Confirm
          </v-btn>
        </div> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import SyncChannelForm from '@/modules/app/views/channel/channel-resource/SyncChannelForm.vue'
// import SyncChannelNoteForm from '@/modules/app/views/channel/note-resource/SyncChannelNoteForm.vue'
import SyncCategoryForm from '@/modules/common/views/category-resource/SyncCategoryForm.vue'
import SyncTagForm from '@/modules/common/views/tag-resource/SyncTagForm.vue'

import { notifySuccess } from '@/assets/js/utils'
import SyncLocationForm from '@/modules/merchant/components/branch/SyncLocationForm.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    // SyncChannelForm,
    // SyncChannelNoteForm,
    SyncCategoryForm,
    SyncTagForm,
    SyncLocationForm,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const syncAble = ref(false)

    return {
      syncAble,
      toggleSync: () => {
        syncAble.value = true
        setTimeout(() => {
          syncAble.value = false
          notifySuccess({ content: 'Relations Update Succeeded.' })
        }, 100)
      },
    }
  },
}
</script>
