<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <voucher-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      :cols="12"
      :sku="sku"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { extractErrors } from '@/assets/js/utils'
import { updateVoucher } from '@api/product'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import VoucherForm from './VoucherForm.vue'

export default {
  components: { VoucherForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    sku: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = { ...form }
      if (!props.resource) {
        data.sku_id = props.sku.id
      }
      if (data.applicable_at) {
        data.applicable_at = moment(data.applicable_at).startOf('day').toString()
      }
      if (data.last_purchasable_at) {
        data.last_purchasable_at = moment(data.last_purchasable_at).startOf('day').toString()
      }
      if (data.expires_at) {
        data.expires_at = moment(data.expires_at).startOf('day').toString()
      }

      const request = updateVoucher(props.resource.id, data)
      await request
        .then(res => {
          emit('updated', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = extractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
