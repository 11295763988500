<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>

        <v-tabs
          v-model="currentTab"
          show-arrows
          class="user-tabs mt-2"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :disabled="tab.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="productData"
          id="user-tabs-content"
          v-model="currentTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <product-overview
              :product="productData"
              @updated="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <product-media-gallery
              v-if="productData"
              :product="productData"
              @updated="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <product-sku-list
              :product="productData"
              @updated="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <product-sku-attribute
              :product="productData"
              @change="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <div class="row mb-2  d-flex align-items-center pt-5 pr-5 pl-5">
              <h3 class="col-6">
                SKU Price
                <span v-if="productData.nature === 'service'">Strategy Group</span>
              </h3>
              <v-spacer />

              <!-- <act-as-channel-options /> -->
            </div>
            <product-sku-price-list
              v-for="sku in productData.skus"
              :key="sku.id"
              :product="productData"
              :sku="sku"
            />
            <p>For tangible and voucher - each SKU has one group and one price.</p>
            <p>For service - each SKU has multiple groups, each group may have multiple prices. Each time slot belongs to 1 group.</p>
          </v-tab-item>

          <v-tab-item>
            <product-relations
              :product="productData"
            />
          </v-tab-item>

          <v-tab-item>
            <product-seo
              :product="productData"
              @updated="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <template v-if="productData.nature === 'service'">
              <product-sku-slot-list
                v-for="sku in productData.skus"
                :key="sku.id"
                :product="productData"
                :sku="sku"
                class="mb-5"
              />
            </template>
            <template v-else-if="productData.nature === 'voucher'">
              <product-sku-voucher-list
                v-for="sku in productData.skus"
                :key="sku.id"
                class="mb-5"
                :product="productData"
                :sku="sku"
                @updated="reloadProduct"
              />
            </template>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import {
  mdiAlphaABox,
  mdiAlphaPBox,
  mdiAlphaSBox,
  mdiAlphaSBoxOutline,
  mdiCalendarClock,
  mdiCash100,
  mdiCurrencyUsd,
  mdiPictureInPictureBottomRight,
  mdiTag,
} from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import ProductMediaGallery from '../media-resource/ProductMediaGallery.vue'
import ProductOverview from './product-overview/ProductOverview.vue'
import ProductRelations from './product-overview/ProductRelations.vue'
import ProductSeo from './product-overview/ProductSeo.vue'
import ProductSkuAttribute from './product-overview/ProductSkuAttribute.vue'
import ProductSkuList from './product-overview/ProductSkuList.vue'
import ProductSkuPriceList from './product-overview/ProductSkuPriceList.vue'
import ProductSkuSlotList from './product-overview/ProductSkuSlotList.vue'
import ProductSkuVoucherList from './product-overview/ProductSkuVoucherList.vue'
// import ActAsChannelOptions from '../../channel/channel-resource/ActAsChannelOptions.vue'
import useProductView from '../useProduct'

export default {
  components: {
    ProductOverview,
    ProductSkuList,
    ProductSkuPriceList,
    ProductSkuSlotList,
    ProductSkuVoucherList,
    ProductSkuAttribute,
    ProductMediaGallery,
    ProductRelations,
    ProductSeo,

    // ActAsChannelOptions,
  },

  setup() {
    const { loadProduct, productData } = useProductView()

    const reloadProduct = () => {
      productData.value = null

      return loadProduct(router.currentRoute.params.id).then(() => store.dispatch('merchant/setCurrent', productData.value.merchant))
    }

    // UnRegister on leave

    const tabs = computed(() => {
      const options = [
        { icon: mdiAlphaPBox, title: 'OVERVIEW' },
        { icon: mdiPictureInPictureBottomRight, title: 'GALLERY' },
        { icon: mdiAlphaSBoxOutline, title: 'SKUS' },
        { icon: mdiAlphaABox, title: 'ATTRIBUTES' },
        { icon: mdiCurrencyUsd, title: 'PRICING' },
        { icon: mdiTag, title: 'RELATIONS' },
        { icon: mdiAlphaSBox, title: 'SEO' },

        // { icon: mdiFileOutline, title: 'POLICIES' },
      ]
      if (productData.value && productData.value.nature === 'service') {
        options.push({ icon: mdiCalendarClock, title: 'TIME SLOTS' })
      }
      if (productData.value && productData.value.nature === 'voucher') {
        options.push({ icon: mdiCash100, title: 'VOUCHERS' })
      }

      return options
    })
    const currentTab = ref(0)

    const switchToDefaultTab = () => {
      const tab = router.currentRoute.query.tab || null
      const index = tabs.value.findIndex(t => t.title === tab)
      currentTab.value = Math.max(0, index)
    }

    onMounted(() => reloadProduct().then(switchToDefaultTab))

    return {
      tabs,
      productData,
      reloadProduct,

      currentTab,
    }
  },
}
</script>
