<template>
  <v-row>
    <v-col cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex align-center justify-space-between">
          <span>{{ t(product.title) }}</span>
          <div>
            <v-chip
              small
              color="primary"
              :class="`v-chip-light-bg text-sm font-weight-semibold text-capitalize ml-2 primary--text`"
            >
              {{ product.nature }}
            </v-chip>
            <v-chip
              small
              :color="product.status === 'published' ? 'success' : ''"
              :class="{ 'success--text': product.status === 'published' } "
              class="v-chip-light-bg text-sm font-weight-semibold ml-2 text-capitalize"
            >
              {{ product.status }}
            </v-chip>
            <v-chip
              v-if="product.status !== 'unpublished'"
              small
              class="v-chip-light-bg text-sm font-weight-semibold ml-2 text-capitalize"
            >
              {{ product.active ? 'Active' : 'Inactive' }}
            </v-chip>
            <v-icon
              small
              class="ml-2"
            >
              {{ product.visible ? icons.mdiEye : icons.mdiEyeRemove }}
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <b>Product slug:</b>
          {{ product.slug }}
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          Content
        </v-card-title>
        <v-card-text>
          <div
            class="mb-4 html-content"
            v-html="t(product.content)"
          >
          </div>
        </v-card-text>
      </v-card>

      <v-card
        v-if="product.nature_info && product.nature_info.service"
        class="mt-2"
      >
        <v-card-title>
          Nature Info
        </v-card-title>
        <v-card-text>
          <div>
            <div>Participants: {{ product.nature_info.service.min_participant }} - {{ product.nature_info.service.max_participant }} ppl</div>
            <div>Duration: {{ product.nature_info.service.min_duration_minute }} - {{ product.nature_info.service.max_duration_minute }} mins</div>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          Stats
        </v-card-title>
        <v-card-text>
          <v-row
            v-if="product.stats"
          >
            <v-col cols="4">
              {{ product.stats.rating }}
              <v-icon
                size="20"
                color="warning"
              >
                {{ icons.mdiStar }}
              </v-icon>
            </v-col>
            <v-col cols="4">
              {{ product.stats.no_comment }}
              <v-icon
                size="20"
                color="info"
              >
                {{ icons.mdiComment }}
              </v-icon>
            </v-col>
            <v-col cols="4">
              {{ product.stats.no_purchase }}
              <v-icon
                size="20"
                color="warning"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          Media
        </v-card-title>
        <v-card-text>
          <div v-if="!product.media.length">
            No Media uploaded. Please upload from GALLERY.
          </div>
          <div>
            <v-avatar
              v-for="media in product.media"
              :key="media.id"
              size="120"
              rounded
            >
              <v-img
                :src="media.instance.thumbnail"
              ></v-img>
            </v-avatar>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          SKU overview
        </v-card-title>
        <v-card-text>
          <span
            v-for="sku in product.skus"
            :key="sku.id"
          >
            {{ t(sku.name) }},
          </span>
          <div>Price range: {{ product.min_sku_price | price }} - {{ product.max_sku_price | price }}</div>
          </div>
        </v-card-text>
      </v-card>

      <div class="text-center mt-5">
        <v-btn
          color="secondary"
          class="me-3"
          large
          @click="$router.push({ name: 'product-update-resource', query: { product_id: product.id } })"
        >
          Edit
        </v-btn>

        <!-- <v-btn
          v-if="product.status !== 'published'"
          color="primary"
          class="me-3"
          large
          @click="publishProduct(product.id).then(() => { $emit('updated'); notifySuccess({ content: 'Product Published.' }) })"
        >
          Publish
        </v-btn>

        <v-btn
          v-if="product.status === 'published'"
          color="error"
          class="me-3"
          @click="unpublishProduct(product.id).then(() => $emit('updated'))"
        >
          Unpublish
        </v-btn> -->
      </div>

      <div class="text-center mt-5">
        <a
          :href="preViewUrl"
          target="_blank"
          class="text-decoration-none"
        >
          Preview This Product
        </a>
      </div>
    </v-col>

    <v-col cols="4">
      <v-card class="pt-5">
        <v-card-title class="justify-center flex-column">
          {{ t(product.merchant.name) }}
        </v-card-title>
        <v-card-text v-show="product.nature === 'tangible'">
          <div>shipping fee: {{ product.merchant.shipping_rule.shipping_fee | price }}</div>
          <div>waived over subtotal: {{ product.merchant.shipping_rule.waived_at_subtotal | price }}</div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <!-- <router-link
            class="me-3 text-decoration-none"
            :to="{ name: 'merchant-view', params: { id: product.merchant.id }, query: { tab: product.nature === 'tangible' ? 'SHIPPING FEE' : '' } }"
          >
            Edit
          </router-link> -->
        </v-card-actions>
      </v-card>

      <v-card class="mt-2">
        <v-card-title class="justify-center">
          Policy
        </v-card-title>
        <v-card-text>
          <div
            class="mb-4 short-policy"
            v-html="t(product.policy.cancellation_text)"
          >
          </div>
          ...
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title class="justify-center">
          Order Remark
        </v-card-title>
        <v-card-text>
          <template v-if="product.order_remark">
            <h2 class="text-xl font-weight-semibold mb-2">
            </h2>
            <div
              class="mb-4 short-policy"
              v-html="t(product.order_remark.content)"
            >
            </div>
            ...
          </template>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title class="justify-center flex-column">
          Datetimes
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <span>Published At: </span>
            <span v-if="product.published_at">{{ product.published_at | date }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Created At: </span>
            {{ product.created_at | date }}
          </div>
          <div class="d-flex justify-space-between">
            <span>Updated At: </span>
            {{ product.updated_at | date }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { notifySuccess } from '@/assets/js/utils'
import { t } from '@/plugins/i18n'
import { date, price } from '@core/utils/filter'
import { mdiComment, mdiCurrencyUsd, mdiEye, mdiEyeRemove, mdiStar } from '@mdi/js'
import useProduct from '../../useProduct'

export default {
  filters: { price, date },

  props: {
    product: { type: Object, required: true },
  },

  setup(props) {
    const { publishProduct, unpublishProduct } = useProduct()
    const previewUrl = process.env.VUE_APP_PRODUCT_PREVIEW_URL

    return {
      publishProduct,
      unpublishProduct,
      notifySuccess,
      t,
      preViewUrl: previewUrl + props.product.slug,

      icons: {
        mdiStar,
        mdiComment,
        mdiCurrencyUsd,
        mdiEye,
        mdiEyeRemove,
      },
    }
  },
}
</script>

<style lang="scss">
.short-policy {
  max-height: 100px;
  overflow: hidden;
}
.html-content {
  img {
    max-width: 100%;
  }
}
</style>
