<template>
  <div>
    <attribute-type-aside
      v-if="making"
      :active="true"
      @changed="made"
    ></attribute-type-aside>
    <v-form class="attribute-type-form">
      <v-card>
        <v-card-title class="d-flex">
          <slot name="title"></slot>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="form.sync_ids"
                placeholder="Select at least one Attribute Type"
                :items="attributeTypesOptions"
                item-text="title"
                item-value="value"
                label="Attribute Types"
                outlined
                chips
                multiple
                hide-details
                disabled
                @change="handleSync"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="searchText"
                placeholder="Search Attribute TYPE name"
              >
                <template v-slot:append>
                  <a @click="making = true">+new</a>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-alert
          v-for="msg in errors"
          :key="msg"
          color="warning"
        >
          {{ msg }}
        </v-alert>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import productStore from '@/modules/product/store'
import store from '@/store'
import axios from '@axios'
import { } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import AttributeTypeAside from './AttributeTypeAside.vue'

export default {
  components: { AttributeTypeAside },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /merchant/products/1/sync-attributeTypes
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    store.dispatch('product/fetchAllAttributeTypes')

    const searchText = ref('')

    const matchText = type => {
      const q = searchText.value.toLowerCase()
      const name = JSON.stringify(type.name).toLowerCase()

      return name.includes(q) || props.selectedIds.includes(type.id)
    }

    const attributeTypesOptions = computed(() => productStore.state.attributeTypes.filter(matchText).map(type => ({ title: type.name.en, value: type.id })))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const loadEachTypeValues = () =>
      form.value.sync_ids.map(attributeTypeId =>
        store.dispatch('product/fetchAttributeValuesByType', {
          take: 999,
          attributeTypeId,
        }),
      )

    onMounted(() => loadEachTypeValues())

    const handleSync = () => {
      // each type, read values
      Promise.all(loadEachTypeValues()).then(() => {
        emit('change', form.value.sync_ids)
      })

      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
      })
    }

    // Promise.all(loadEachTypeValues()).then(() => {
    //   emit('change')
    // })

    const making = ref(false)
    const made = async resource => {
      console.log('made resource', resource)
      productStore.state.attributeTypes.push(resource)

      form.value.sync_ids.push(resource.id)
      await handleSync()

      making.value = false
    }

    return {
      form,
      searchText,

      attributeTypesOptions,
      handleSync,

      making,
      made,
    }
  },
}
</script>

<style lang="scss">
@import '~@/assets/styles/variables';

.attribute-type-form {
  .v-label:not(.v-label--active) {
    color: var(--v-error-base);
  }
}
</style>
